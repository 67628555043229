<template>
  <div class="ordered-list" v-for="(item, index) in items" :key="index">
    <div class="ordered-list-number" :style="{ backgroundColor: item.backgroundColor }">
      {{ getNumber(++index) }}
    </div>
    <div class="ordered-list-item" v-html="item.content"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    items: Array
  },
  setup() {
    const getNumber = (index: number): string => (index < 10 ? `0${index}` : `${index}`);

    return { getNumber };
  }
});
</script>

<style scoped lang="scss">
@import './OrderedList.scss';
</style>
