
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    items: Array
  },
  setup() {
    const getNumber = (index: number): string => (index < 10 ? `0${index}` : `${index}`);

    return { getNumber };
  }
});
