
import { defineComponent, ref } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import ContentHeader from '@/shared/ContentHeader.vue';
import Card from '@/shared/Card.vue';
import OrderedList from '@/shared/OrderedList.vue';

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    ContentWrapper,
    ContentHeader,
    Card,
    OrderedList
  },
  setup() {
    const template = (text: string): string => `<p class="t1">${text}</p>`;

    const items = ref([
      { content: template('케이스 진단 분석') },
      { content: template('임상가이드 제공') },
      { content: template('치료경과 분석') }
    ]);

    const items2 = ref([
      { content: template('우수한 교정유지력 및 환자 초기통증 감소') },
      { content: template('장치의 탈착 편의성 증대 (외부 Soft Layer)') },
      { content: template('내구성 및 소재 안정성 우수') }
    ]);

    return { items, items2 };
  }
});
