<template>
  <div>
    <banner>
      <template v-slot:image>
        <img src="@/assets/images/pro_visaul1.png" alt="" />
      </template>
      <template v-slot:content>
        <h4>정확하고 안전한 디지털투명교정</h4>
        <img src="@/assets/images/pro-v-tit-1.png" alt="" />
      </template>
    </banner>
    <breadcrumb-wrapper></breadcrumb-wrapper>
    <content-wrapper :order="1">
      <template v-slot:header>
        <content-header
          title="<strong>임상자문시스템</strong> : 자문의의 전문적인 임상 가이드 제공"
          subTitle="임상자문의와의 협진으로 체계적인 교정진료계획을 수립."
        >
        </content-header>
      </template>
      <template v-slot:content>
        <card direction="row" :src="require('@/assets/images/magicalign_img_0101.png')">
          <ordered-list :items="items"> </ordered-list>
        </card>
      </template>
    </content-wrapper>
    <content-wrapper :order="2">
      <template v-slot:header>
        <content-header
          title="<strong>Magic Foil</strong> : 3-layer Sheet로 교정력 증대"
          subTitle="오랜 연구 노하우를 축적하여 개발한 투명교정시트 Magic Foil로 완성도 높은 투명교정치료 가능"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <card direction="row" :src="require('@/assets/images/magicalign_img_0102.png')">
          <ordered-list :items="items2"> </ordered-list>
        </card>
      </template>
    </content-wrapper>
    <content-wrapper :order="3">
      <template v-slot:header>
        <content-header
          title="<strong>특허 받은 Attachment</strong> : 치아 견인력 증대"
          subTitle="진단과 케이스 분석에 따라 적절한 교정력 전달을 위한 Attachment 적용"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <img src="@/assets/images/pro_img6_1.png" alt="" />
      </template>
    </content-wrapper>
    <content-wrapper :order="4">
      <template v-slot:header>
        <content-header
          title="<strong>식약처 인증 의료기기</strong>"
          subTitle="식약처 인증 받은 투명교정장치 및 투명교정소재를 사용하여 정확하고 안전한 디지털투명교정 진료 가능"
        >
        </content-header>
      </template>
      <template v-slot:content>
        <div class="img_3">
          <card :src="require('@/assets/images/img-magic-a-img-04.png')">
            <div class="txt-area">
              <div>투명교정장치</div>
              <div class="txt-b-blue">MagicAlign</div>
            </div>
          </card>
          <card :src="require('@/assets/images/img-magic-a-img-05.png')">
            <div class="txt-area">
              <div>투명교정소재</div>
              <div class="txt-b-blue">Magic Foil</div>
            </div>
          </card>
          <card :src="require('@/assets/images/img-magic-a-img-06.png')"> </card>
        </div>
      </template>
    </content-wrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import ContentHeader from '@/shared/ContentHeader.vue';
import Card from '@/shared/Card.vue';
import OrderedList from '@/shared/OrderedList.vue';

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    ContentWrapper,
    ContentHeader,
    Card,
    OrderedList
  },
  setup() {
    const template = (text: string): string => `<p class="t1">${text}</p>`;

    const items = ref([
      { content: template('케이스 진단 분석') },
      { content: template('임상가이드 제공') },
      { content: template('치료경과 분석') }
    ]);

    const items2 = ref([
      { content: template('우수한 교정유지력 및 환자 초기통증 감소') },
      { content: template('장치의 탈착 편의성 증대 (외부 Soft Layer)') },
      { content: template('내구성 및 소재 안정성 우수') }
    ]);

    return { items, items2 };
  }
});
</script>

<style scoped lang="scss">
@import './MagicAlignView.scss';
</style>
